// modal for videos

import React, { useEffect } from "react";
import styled, { keyframes } from "styled-components";
import ReactPlayer from "react-player";
import ScrollLock from "react-scrolllock";
import {
  mainWhite,
  highlight,
  mainBlack,
  transHover,
  screen,
} from "../common/variables";
import Close from "../../images/svg/close-icon.svg";

const scaleInBig = keyframes`
0% {
  opacity: 0;
  transform: scale(0.7);
}
100% {
  opacity: 1;
  transform: scale(1);
}
`;

const Wrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.9);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: scroll;
  padding: 15px;
  height: 100%;
  @media ${screen.small} {
    padding: 60px;
  }
  @media ${screen.medium} {
    padding: 100px;
  }
  @media ${screen.large} {
    padding: 50px;
  }
  @media ${screen.xlarge} {
    padding: 0;
  }

  .inner {
    max-width: 1280px;
    width: 100%;
    background: ${mainWhite};
    padding: 8px 8px 16px 8px;
    position: relative;
    animation: ${scaleInBig} 0.4s ease-in-out;
    @media ${screen.small} {
      padding: 12px 12px 26px 12px;
      max-width: 750px;
    }
    @media ${screen.medium} {
      margin-top: 25px;
      max-width: 800px;
    }
    @media ${screen.large} {
      margin-top: 100px;
      max-width: 940px;
    }
    @media ${screen.minilarge} {
      margin-top: 110px;
      max-width: 730px;
    }
    @media ${screen.xlarge} {
      margin-top: 0;
      max-width: 1280px;
    }

    &__video-container {
      position: relative;
      padding-top: 56.25%;
      width: 100%;
      margin: 0 auto;

      .video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100% !important;
      }
    }

    &__texts-container {
      margin-top: 10px;
      padding: 0 6px;
      @media ${screen.small} {
        margin-top: 28px;
      }
      .headings {
        display: flex;
        justify-content: space-between;

        &__title {
          color: ${mainBlack};
          font-size: 1.7rem;
          padding-right: 30px;
          @media ${screen.small} {
            font-size: 2.3rem;
          }
        }

        &__year {
          color: ${highlight};
          font-size: 1.7rem;
          @media ${screen.small} {
            font-size: 2.3rem;
          }
        }
      }

      .descriptions {
        display: none;
        margin-top: 4px;
        @media ${screen.large} {
          display: flex;
          align-items: flex-start;
        }

        span {
          color: ${highlight};
          font-size: 0.95rem;
          margin-right: 7px;
          @media ${screen.small} {
            font-size: 1.15rem;
            margin-right: 10px;
          }
        }

        li {
          max-width: 600px;
          margin-top: 10px;
          &:first-child {
            margin-top: 0;
          }
          p {
            color: ${mainBlack};
            font-size: 0.92rem;
            white-space: pre-line;
            @media ${screen.small} {
              font-size: 1.15rem;
            }
          }
        }
      }
    }

    .close-icon {
      cursor: pointer;
      position: absolute;
      top: -11px;
      right: -6px;
      background: ${mainWhite};
      border-radius: 50%;
      width: 47px;
      height: 47px;
      display: flex;
      align-items: center;
      justify-content: center;
      @media ${screen.small} {
        width: 56px;
        height: 56px;
        top: -23px;
        right: -23px;
      }

      @media ${screen.large} {
        width: 60px;
        height: 60px;
        top: -27px;
        right: -27px;
      }

      svg {
        width: 17px;
        @media ${screen.small} {
          width: 20px;
        }
        .stroke {
          fill: ${mainBlack};
          transition: ${transHover};
        }
        &:hover {
          .stroke {
            fill: ${highlight};
          }
        }
      }
    }
  }
`;

const VideoModal = ({
  showModal,
  setModal,
  videoURL,
  videoTitle,
  videoDate,
  videoCredits,
  withDate,
}) => {
  const date = withDate && videoDate.toString().slice(0, 4);

  // listener for closing the modal by user click and key press
  useEffect(() => {
    if (showModal) {
      const detectTarget = (e) => {
        if (e.target.nodeName === "svg" || e.target.nodeName === "path") {
          return false;
        } else {
          // close modal if user has clicked outside the modal
          if (e.target.className.includes("Wrapper")) {
            closeModal();
          }
        }
      };
      // close modal if user press esc key
      const detectKey = (e) => {
        if (e.keyCode === 27) {
          closeModal();
        }
      };

      window.addEventListener("click", detectTarget);

      window.addEventListener("keydown", detectKey);

      return () => {
        window.removeEventListener("click", detectTarget);
        window.removeEventListener("keydown", detectKey);
      };
    }
  });

  // close video modal
  const closeModal = (e) => {
    setModal(false);
  };

  return (
    <ScrollLock>
      <Wrapper>
        <div className="inner">
          <div className="inner__video-container">
            <ReactPlayer
              className="video"
              url={videoURL}
              preload="true"
              controls={true}
            />
          </div>
          <div className="inner__texts-container">
            <div className="headings">
              <h1 className="headings__title">{videoTitle}</h1>
              <h2 className="headings__year">{date}</h2>
            </div>

            {videoCredits && (
              <p className="descriptions">
                <span>Credits:</span>
                <p>{videoCredits}</p>
              </p>
            )}
          </div>
          <span className="close-icon" onClick={closeModal}>
            <Close />
          </span>
        </div>
      </Wrapper>
    </ScrollLock>
  );
};

export default VideoModal;
