import React, { useState } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import Layout from "../components/layout";
import { flattenArr } from "../functions";
import SEO from "../components/seo";
import VideoModal from "../components/video-modal";
import {
  screen,
  mainBlack,
  innerWidth,
  mainWhite,
  highlight,
  transHover,
} from "../components/common/variables";
import { getVimeoId } from "../functions";
import ReactPlayer from "react-player";

const Wrapper = styled.div`
  padding-top: 124px;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 80px;
  @media ${screen.small} {
    padding-top: 135px;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 90px;
  }
  @media ${screen.medium} {
    padding-top: 270px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 120px;
  }
  @media ${screen.xlarge} {
    padding-top: 328px;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 180px;
  }

  .directing {
    max-width: ${innerWidth};
    margin: 0 auto;
    @media ${screen.small} {
      margin: 0 auto;
    }
    @media ${screen.medium} {
      margin: 0 auto;
    }
    @media ${screen.large} {
      margin: 0 auto;
    }

    &__heading {
      font-size: 2.1rem;
      @media ${screen.small} {
        font-size: 2.7rem;
      }
    }

    &__description {
      margin: 12px 0 22px 0;
      max-width: 940px;
      @media ${screen.small} {
        margin: 12px 0 40px 0;
      }

      p {
        font-size: 1rem;
        font-weight: 300;
        margin-bottom: 16px;
        @media ${screen.small} {
          font-size: 1.1rem;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }

      a {
        color: ${highlight};
        text-decoration: underline;
      }

      strong {
        font-weight: 900;
      }

      h1 {
        font-size: 2.2rem;

        margin: 10px 0;
      }
      h2 {
        font-size: 2rem;

        margin: 10px 0;
      }
      h3 {
        font-size: 1.8rem;

        margin: 10px 0;
      }
      h4 {
        font-size: 1.6rem;

        margin: 10px 0;
      }
      h5 {
        font-size: 1.4rem;

        margin: 10px 0;
      }
      h6 {
        font-size: 1.2rem;

        margin: 10px 0;
      }

      &--secondary {
        margin: 40px 0 0 0;
        @media ${screen.medium} {
          margin: 60px 0 0 0;
        }
      }
    }

    .filter {
      .heading {
        font-size: 1.3rem;
        margin: 0 0 22px 0;
      }

      .filter-list {
        display: flex;
        flex-wrap: wrap;
        max-width: 1000px;
        margin-top: 20px;

        li {
          font-size: 1.05em;
          font-weight: 300;
          margin-right: 10px;
          margin-bottom: 15px;
          @media ${screen.small} {
            font-size: 1.25rem;
            margin-right: 22px;
            margin-bottom: 20px;
          }
          @media ${screen.medium} {
            font-size: 1.42rem;
            margin-right: 20px;
            margin-bottom: 18px;
          }

          .category {
            cursor: pointer;
            transition: ${transHover};

            &:hover {
              color: ${highlight};
            }

            &--active {
              color: ${highlight};
            }
          }

          .slash {
            padding-left: 5px;
            @media ${screen.small} {
              padding-left: 15px;
            }
          }

          &:last-child {
            .slash {
              visibility: hidden;
            }
          }
        }
      }
    }

    &__video-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: 22px 0 0 0;

      .video-item {
        height: 200px;
        overflow: hidden;
        position: relative;
        margin: 8px 0;
        width: 100%;

        @media ${screen.small} {
          width: 49%;
          height: 230px;
        }
        @media ${screen.medium} {
          width: 32.5%;
          height: 240px;
        }
        @media ${screen.large} {
          height: 270px;
        }
        @media ${screen.xlarge} {
          margin: 10px 0;
          height: 335px;
        }

        &__texts {
          cursor: pointer;
          position: absolute;
          bottom: 0;
          background: rgba(37, 61, 95, 0.5);
          top: 0;
          left: 0;
          right: 0;
          transition: ${transHover};
          padding-top: 20px;
          padding-left: 20px;
          @media ${screen.withCursor} {
            &:hover {
              background: rgba(134, 206, 207, 0.9);
            }
          }

          .video-title {
            color: ${mainWhite};

            font-size: 1.2rem;
            position: relative;
            max-width: 440px;
            padding-right: 20px;
            @media ${screen.small} {
              font-size: 1.3rem;
            }
            @media ${screen.xlarge} {
              padding-right: 0;
            }

            &::after {
              content: "";
              display: block;
              width: 45px;
              height: 3px;
              margin: 18px 0;
              background: ${mainWhite};
            }
          }

          .video-subtitle {
            color: ${mainWhite};
            font-size: 0.92rem;
            display: inline-block;
            margin-right: 5px;
            @media ${screen.small} {
              font-size: 1rem;
            }

            &:last-child {
              .video-subtitle--comma {
                display: none;
              }
            }
          }
        }

        &__video {
          width: 100% !important;
          height: 100% !important;
          pointer-events: none;
        }
        .react-player__play-icon,
        .react-player__shadow {
          display: none !important;
        }
      }

      &::after {
        content: " ";
        visibility: hidden;
        width: 100%;
        @media ${screen.small} {
          width: 49%;
        }
        @media ${screen.medium} {
          width: 32.5%;
        }
      }
    }

    .load-more-btn {
      background: ${mainBlack};
      display: table;
      color: ${mainWhite};
      font-size: 1.1rem;
      padding: 15px 30px 12px 30px;
      transition: ${transHover};
      margin: 45px 0 0 0;
      border: 0;
      cursor: pointer;

      &:hover {
        background: ${highlight};
      }
    }
  }
`;

const WorkPage = ({ data }) => {
  // get all categories from prismic project list
  const getCategories = data.projects.edges.map((item) => {
    return item.node.data.categories.map(
      (each) => each.category.document && each.category.document.data.title.text
    );
  });

  // array of categories to use for filtering
  const allCategories = flattenArr(getCategories);

  // returned a number for slicing array (hack for load more)
  const searchParamNum =
    typeof window !== "undefined" &&
    Number(window.location.search.substring(1).replace(/-/g, " "));

  // number of project to show
  const [currentProjectLength, setCurrentProjectLength] = useState(
    searchParamNum || 9
  );

  // hide/show modal state
  const [showModal, setModal] = useState(false);

  // video modal details
  const [selectedVideo, setSelectedVideo] = useState({
    videoURL: null,
    videoTitle: null,
    videoCredits: null,
    videoDate: null,
  });

  const [selectedCategory, setSelectedCategory] = useState("All");

  const {
    heading,
    description,
    subheading,
    title_tag,
    meta_description,
  } = data.content.data;

  const expandMoreProject = () => {
    setCurrentProjectLength(currentProjectLength + 9);

    window.history.pushState({ page: 1 }, "", `?${currentProjectLength + 9}`);
  };

  const projects = data.projects.edges
    .map((project) => {
      return {
        id: project.node.id,
        title: project.node.data.title.text,
        video_url: project.node.data.video_url,
        credits: project.node.data.credits,
        category: project.node.data.categories.map(
          (item) =>
            item.category.document && item.category.document.data.title.text
        ),
        date: project.node.data.date
          ? Number(project.node.data.date.split("-").join(""))
          : Number(
              project.node.first_publication_date
                .slice(0, 10)
                .split("-")
                .join("")
            ),
        withDate: project.node.data.date,
      };
    })
    .sort((a, b) => b.date - a.date);

  const filteredBlogs =
    selectedCategory === "All"
      ? projects
      : projects.filter((proj) =>
          proj.category.some((cat) => cat === selectedCategory)
        );

  return (
    <Layout>
      <SEO title={title_tag} description={meta_description} />
      <Wrapper>
        <div className="directing">
          <h1 className="directing__heading">{heading.text}</h1>
          <div
            className="directing__description"
            dangerouslySetInnerHTML={{ __html: description.html }}
          />

          <div className="filter">
            {subheading && <h3 className="heading">{subheading.text}</h3>}

            <ul className="filter-list">
              <li>
                <span
                  className={
                    selectedCategory === `All`
                      ? `category category--active`
                      : `category`
                  }
                  onClick={() => {
                    if (selectedCategory !== `All`) {
                      setSelectedCategory(`All`);
                    }
                  }}
                >
                  All
                </span>{" "}
                <span className="slash"> / </span>
              </li>
              {allCategories.map((category) => (
                <li key={category}>
                  <span
                    className={
                      selectedCategory === category
                        ? `category category--active`
                        : `category`
                    }
                    onClick={() => {
                      if (selectedCategory !== category) {
                        setSelectedCategory(category);
                      }
                    }}
                  >
                    {category}
                  </span>
                  <span className="slash"> / </span>
                </li>
              ))}
            </ul>
          </div>

          <ul className="directing__video-list">
            {filteredBlogs.slice(0, currentProjectLength).map((item, i) => (
              <li
                key={item.id}
                className="video-item"
                onClick={() => {
                  setSelectedVideo({
                    videoURL: `https://vimeo.com/${getVimeoId(item.video_url)}`,
                    videoTitle: item.title,
                    videoCredits: item.credits,
                    videoDate: item.date,
                    withDate: item.withDate,
                  });
                  setModal(true);
                }}
              >
                <div className="video-item__texts">
                  <h2 className="video-title">{item.title}</h2>
                  {item.category.map((cat, i) => (
                    <p className="video-subtitle" key={cat || i}>
                      {cat}
                      <span className="video-subtitle--comma">,</span>
                    </p>
                  ))}
                </div>
                <ReactPlayer
                  className="video-item__video"
                  url={`https://vimeo.com/${getVimeoId(item.video_url)}`}
                  light={true}
                />
              </li>
            ))}
          </ul>
          {currentProjectLength < data.projects.edges.length && (
            <button className="load-more-btn" onClick={expandMoreProject}>
              LOAD MORE
            </button>
          )}
        </div>
        {showModal && (
          <VideoModal
            {...selectedVideo}
            showModal={showModal}
            setModal={setModal}
          />
        )}
      </Wrapper>
    </Layout>
  );
};

export default WorkPage;

export const dataQuery = graphql`
  {
    content: prismicWorkPage {
      data {
        heading {
          text
        }
        description {
          html
        }
        subheading {
          text
        }
        title_tag
        meta_description
      }
    }
    projects: allPrismicProject {
      edges {
        node {
          first_publication_date
          id
          data {
            title {
              text
            }
            date
            video_url
            credits
            categories {
              category {
                document {
                  ... on PrismicCategory {
                    data {
                      title {
                        text
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
